import React from 'react';
import AppointmentForm from '../../AppointmentForm';
import contact from '../../../../../images/backgrounds/contact.webp';
import icon1 from '../../../../../images/icons/icon_mail.svg';
import icon2 from '../../../../../images/icons/icon_calling.svg';
import icon3 from '../../../../../images/icons/icon_map_mark.svg';

// Icon mapping for dynamic rendering
const icons = { icon1, icon2, icon3 };

const contactInfo = [
  { title: 'Phone', subTitle: '04224169202', icon: 'icon2' },
  { title: 'Email Us', subTitle: 'info@xnovaq.com', icon: 'icon1' },
  { title: 'Our Location', subTitle: 'Hanudev InfoPark, Coimbatore', icon: 'icon3' },
];

export default function AppointmentWithContactInfoSection() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h2 className="cs_fs_40 cs_medium mb-0">Get in Touch with Our Team</h2>
          <div className="cs_height_42 cs_height_xl_25" />
          <AppointmentForm />
        </div>
        <div className="col-lg-4">
          <div className="cs_height" />
          <h2 className="cs_fs_40 cs_medium mb-0">Contact Info</h2>
          <div className="row">
            <div className="col-md-6 col-lg-12">
              <img
                src={contact}
                alt="Appointment"
                className="cs_radius_25 w-100"
              />
            </div>
            <div className="col-md-6 col-lg-12">
              <ul className="cs_contact_info cs_style_1 cs_mp0">
                {contactInfo.map((item, index) => (
                  <li key={index} className="d-flex align-items-start">
                    <div>
                      <h3 className="cs_fs_24 cs_semibold mb-1">{item.title}</h3>
                      {item.title === 'Phone' ? (
                        <div className="d-flex flex-row align-items-start gap-2">
                          <img
                            src={icons[item.icon]} // Dynamically set the icon
                            alt={item.title}
                            className="cs_icon me-3"
                            style={{
                              width: '24px',
                              height: '24px',
                              filter: 'brightness(0)',
                            }}
                          />
                          <div>
                            {item.subTitle.split(',').map((phone, i) => (
                              <p key={i} className="mb-0 cs_heading_color">
                                <a href={`tel:${phone}`} className="cs_heading_color text-decoration-none">
                                  {phone}
                                </a>
                              </p>
                            ))}
                          </div>
                        </div>
                      ) : item.title === 'Email Us' ? (
                        <div className="d-flex flex-row gap-2">
                          <img
                            src={icons[item.icon]}
                            alt={item.title}
                            className="cs_icon me-3"
                            style={{
                              width: '24px',
                              height: '24px',
                              filter: 'brightness(0)',
                            }}
                          />
                          <p className="mb-0 cs_heading_color">
                            <a href={`mailto:${item.subTitle}`} className="cs_heading_color text-decoration-none">
                              {item.subTitle}
                            </a>
                          </p>
                        </div>
                      ) : item.title === 'Our Location' ? (
                        <div className="d-flex flex-row gap-2">
                          <img
                            src={icons[item.icon]}
                            alt={item.title}
                            className="cs_icon me-3"
                            style={{
                              width: '24px',
                              height: '24px',
                              filter: 'brightness(0)',
                            }}
                          />
                          <p className="mb-0 cs_heading_color">
                            <a
                              href={"https://maps.app.goo.gl/dDeLGgrop4coyRuH9"}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="cs_heading_color text-decoration-none"
                            >
                              {item.subTitle}
                            </a>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
